@font-face {
    font-family: 'Nunito';
    src: url("fonts/Nunito-ExtraBold.ttf") format('opentype');
    font-display: fallback;
}

:root {
    --color-font: rgb(252, 250, 250) !important;
    --color-background: rgb(24, 24, 24) !important;
    --color-background-header: rgb(17, 17, 17) !important;
    --color-nav: #21ddfe !important;
    --main-font: Roboto, sans-serif!important;
    --second-font: Nunito, sans-serif!important;
    --section-distance: 80px;
    --section-distance-mobile: 35px;
}

html, body { 
    height: 100%; 
    min-height: 100% !important;
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
}

body { 
    margin: 0; 
    font-family: var(--main-font);
    background-color: var(--color-background);
    color: var(--color-font);
}

.no-mobile {
    display: unset!important;
    visibility: visible;
}

.mobile {
    display: none!important;
    visibility: hidden;
}

.grecaptcha-badge {
    display: unset!important;
    visibility: visible;
}

@media only screen and (max-width: 900px){
    .mobile {
      display: unset!important;
      visibility: visible;
    }
  
    .no-mobile {
        display: none!important;
        visibility: hidden;
    }
  
    .grecaptcha-badge {
      display: none!important;
      visibility: hidden;
    }
  }